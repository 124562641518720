const comSwipeIcon = (() => {

  // <div class="com-swipe-icon-box js-com-swipe-icon">
  //   <div class="com-swipe-icon">
  //     <span class="material-symbols-outlined">swipe</span>
  //   </div>
  //   <p class="com-swipe-icon-txt">左右にスワイプして<br>ご覧ください</p>
  // </div>

  const trigger = document.querySelectorAll('.js-com-swipe-icon-trigger');
  const icon = document.querySelectorAll('.js-com-swipe-icon');
  if (trigger.length > 0 && icon.length > 0) {


    for (let i = 0; i < trigger.length; i++) {

      trigger[i].addEventListener('click', (event) => {
        icon[i].style.display = 'none'
      });

      trigger[i].addEventListener('touchstart', (event) => {
        icon[i].style.display = 'none'
      });

    }


  }

})();
export default comSwipeIcon;