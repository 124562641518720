const conceptFeature = (() => {
  if (document.querySelector("body#pageConcept") !== null) {



    let sec = document.querySelector("#jsSecFeature")
    let wrap = sec.querySelector(".js-feature-wrap")


    {
      let area = wrap.querySelector(".js-main-area")
      let box = area.querySelector(".js-bg-box")
      // let img = bgBox.querySelector(".js-img")
      // let imgBox = area.querySelector(".js-img-box")
      // let bg = imgBox.querySelector(".js-bg")

      let to = gsap.to(box, {
        scale: 1.01,
        ease: "none",
        scrollTrigger: {
          trigger: area,
          start: '0% 100%',
          end: '50% 50%',
          scrub: 1,
          // markers: true
        }
      })

      let target = document.querySelector('body');
      let observer = new ResizeObserver((entries) => {
        entries.forEach((entry) => {
          to.scrollTrigger.refresh()
        });
      });
      observer.observe(target);
    }


    // {
    //   let area = wrap.querySelector(".js-main-area")
    //   let bgBox = area.querySelector(".js-bg-box")
    //   let bgImg = bgBox.querySelector(".js-img")
    //   let imgBox = area.querySelector(".js-img-box")
    //   let img = imgBox.querySelector(".js-img")

    //   let to = gsap.to(bgImg, {
    //     filter: "blur(10px)",
    //     ease: "none",
    //     scrollTrigger: {
    //       trigger: img,
    //       start: '0% 40%',
    //       endTrigger: area,
    //       end: '100% 100%',
    //       scrub: 1,
    //       // markers: true
    //     }
    //   })

    //   let target = document.querySelector('body');
    //   let observer = new ResizeObserver((entries) => {
    //     entries.forEach((entry) => {
    //       to.scrollTrigger.refresh()
    //     });
    //   });
    //   observer.observe(target);
    // }







  }
})()
export default conceptFeature;