const comParallax = (() => {

  const parallaxElemBox = document.querySelectorAll('.js-parallax-box');
  const parallaxInner = document.querySelectorAll('.js-parallax-inner');

  if (parallaxElemBox.length > 0 && parallaxInner.length > 0) {


    let sW = window.innerWidth;

    let y

    if (sW > 750) {
      y = -100;
    } else {
      y = -50;
    }


    for (let i = 0; i < parallaxElemBox.length; i++) {

      let to = gsap.to(parallaxInner[i], {
        y: y,
        ease: "none",
        scrollTrigger: {
          trigger: parallaxElemBox[i],
          start: 'top bottom',
          end: 'bottom top',
          scrub: 0,
          // markers: true
        }
      })


      let target = document.querySelector('body');
      let observer = new ResizeObserver((entries) => {
        entries.forEach((entry) => {
          to.scrollTrigger.refresh()
          // console.log('comParallax refresh');
        });
      });
      observer.observe(target);

    }


  }


  const parallaxElem = document.querySelectorAll('.js-parallax-elem');
  if (parallaxElem.length > 0) {
    for (let i = 0; i < parallaxElem.length; i++) {

      let y

      if (parallaxElem[i].classList.contains('gap10') == true) {
        y = 10
      } else if (parallaxElem[i].classList.contains('gap20') == true) {
        y = 20
      } else if (parallaxElem[i].classList.contains('gap30') == true) {
        y = 30
      } else if (parallaxElem[i].classList.contains('gap40') == true) {
        y = 40
      } else if (parallaxElem[i].classList.contains('gap50') == true) {
        y = 50
      } else if (parallaxElem[i].classList.contains('gap60') == true) {
        y = 60
      } else if (parallaxElem[i].classList.contains('gap70') == true) {
        y = 70
      } else if (parallaxElem[i].classList.contains('gap80') == true) {
        y = 80
      } else if (parallaxElem[i].classList.contains('gap90') == true) {
        y = 90
      } else if (parallaxElem[i].classList.contains('gap100') == true) {
        y = 100
      } else if (parallaxElem[i].classList.contains('gap-10') == true) {
        y = -10
      } else if (parallaxElem[i].classList.contains('gap-20') == true) {
        y = -20
      } else if (parallaxElem[i].classList.contains('gap-30') == true) {
        y = -30
      } else if (parallaxElem[i].classList.contains('gap-40') == true) {
        y = -40
      } else if (parallaxElem[i].classList.contains('gap-50') == true) {
        y = -50
      } else if (parallaxElem[i].classList.contains('gap-60') == true) {
        y = -60
      } else if (parallaxElem[i].classList.contains('gap-70') == true) {
        y = -70
      } else if (parallaxElem[i].classList.contains('gap-80') == true) {
        y = -80
      } else if (parallaxElem[i].classList.contains('gap-90') == true) {
        y = -90
      } else if (parallaxElem[i].classList.contains('gap-100') == true) {
        y = -100
      } else {
        y = -100
      }

      let to = gsap.to(parallaxElem[i], {
        y: y,
        ease: "none",
        scrollTrigger: {
          trigger: parallaxElem[i],
          start: 'top bottom',
          end: 'bottom top',
          scrub: 0,
          // markers: true
        }
      })


      let target = document.querySelector('body');
      let observer = new ResizeObserver((entries) => {
        entries.forEach((entry) => {
          to.scrollTrigger.refresh()
          // console.log('comParallax refresh');
        });
      });
      observer.observe(target);

    }
  }

})();
export default comParallax;