const topLinks = (() => {
  if (document.querySelector("body#pageTop") !== null) {



    let secLinks = document.querySelector("#jsSecLinks")

    let linkWrap = secLinks.querySelectorAll(".js-link-wrap")


    linkWrap.forEach(element => {

      let coverArea = element.querySelector(".js-cover-area")

      let tl = gsap.timeline({
        scrollTrigger: {
          invalidateOnRefresh: true,
          trigger: coverArea,
          start: "0% 40%",
          onEnter: () => coverArea.classList.add('none'),
          // onLeave: () => console.log("onLeave"),
          // onEnterBack: () => console.log("onEnterBack"),
          onLeaveBack: () => coverArea.classList.remove('none'),
          // markers: true,
        },
      })

      let target = document.querySelector('body');
      let observer = new ResizeObserver((entries) => {
        entries.forEach((entry) => {
          tl.scrollTrigger.refresh()
        });
      });
      observer.observe(target);

    });






  }
})()
export default topLinks;