const conceptConcept = (() => {
  if (document.querySelector("body#pageConcept") !== null) {



    let sec = document.querySelector("#jsSecConcept")
    let wrap = sec.querySelector(".js-concept-wrap")


    {
      let area = wrap.querySelector(".js-main-area")
      let box = area.querySelector(".js-img-box")

      let to = gsap.to(box, {
        scale: 1,
        ease: "none",
        scrollTrigger: {
          trigger: area,
          start: '0% 100%',
          end: '50% 50%',
          scrub: 1,
          // markers: true
        }
      })


      let target = document.querySelector('body');
      let observer = new ResizeObserver((entries) => {
        entries.forEach((entry) => {
          to.scrollTrigger.refresh()
        });
      });
      observer.observe(target);
    }


    {
      let area = wrap.querySelector(".js-imgs-area")
      let box = area.querySelectorAll(".js-img-box")

      let to0 = gsap.to(box[0], {
        y: -40,
        ease: "none",
        scrollTrigger: {
          trigger: box[0],
          start: '0% 100%',
          end: '100% 0%',
          scrub: 1,
          // markers: true
        }
      })

      let to1 = gsap.to(box[1], {
        y: -160,
        ease: "none",
        scrollTrigger: {
          trigger: box[1],
          start: '0% 100%',
          end: '100% 0%',
          scrub: 1,
          // markers: true
        }
      })


      let target = document.querySelector('body');
      let observer = new ResizeObserver((entries) => {
        entries.forEach((entry) => {
          to0.scrollTrigger.refresh()
          to1.scrollTrigger.refresh()
        });
      });
      observer.observe(target);
    }



  }
})()
export default conceptConcept;