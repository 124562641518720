const topMain = () => {
  if (document.querySelector("body#pageTop") !== null) {



    let secFirst = document.querySelector("#jsSecFirst")
    let copy = secFirst.querySelector(".js-copy")
    let paths = copy.querySelectorAll("path")

    let secMain = document.querySelector("#jsSecMain")
    let imgBoxFlare = secMain.querySelector(".js-img-box-flare")
    let videoBox = secMain.querySelector(".video-box")
    let video = secMain.querySelector(".js-video")


    gsap.set(paths, {
      x: 80,
      y: -100,
      autoAlpha: 0,
      filter: 'blur(8px)',
    })

    gsap.to(paths, {
      x: 0,
      y: 0,
      autoAlpha: 1,
      filter: 'blur(0px)',
      ease: "none",
      duration: 2.4,
      ease: "power3.out",
      stagger: {
        from: "start",
        each: 0.1,
      }
    }, 1)


    setTimeout(() => {
      secFirst.classList.add('none')

      setTimeout(() => {
        imgBoxFlare.classList.add('act')

        setTimeout(() => {
          video.play()
          videoBox.classList.add('act')
        }, 1600);
      }, 1000);
    }, 3600);



  }
}
export default topMain;